.btn {
    border: none;
    padding: 20px 45px;
    font-size: 16px;
    font-weight: 600; 
    text-transform: uppercase;
    cursor: pointer;
}

.btn:hover {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
}

.btn-transparent {
    background: none;
    border: 3px solid;
    border-image: linear-gradient(to right, #AD918B, #2E8976) 1;
}

.btn-color {
    color: white;
    background: linear-gradient(to right, #AD918B, #2E8976);
    
}

/* Ajoutez ceci à votre fichier CSS */

@media (max-width: 780px) {
    .btn {
        padding: 15px 30px; /* Réduisez la taille du bouton */
        font-size: 14px; /* Réduisez la taille du texte */
    }
}
