header {
    width: 100%;
    max-height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 20%;
    position: fixed;
    z-index: 2;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.logo-top {
    width: 20%;
}

@media (max-width: 780px) {
    header {
        justify-content: space-around;
    }

    .logo-top {
        width: 59%;
    }
}