#background {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100vh;
}

.ellipse1 {
    width: 450px;
    position: fixed;
    top: 0;
    right: 0;
}

.ellipse2 {
    width: 370px;
    position: fixed;
    top: 30%;
    right: 20%;
}

.ellipse3 {
    width: 290px;
    position: fixed;
    bottom: 0;
    right: 37%;
}