#legal {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.box-legal {
    margin-top: 100px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 50vw;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    padding: 20px;
}

.box-legal h1 {
    text-align: center;
    margin: 0;
}

.logo-legal {
    width: 60%;
    margin-top: 0;
    margin-bottom: 0;
}

.box-legal h2 {
    color: #747474;
    border-bottom: 1px #747474 solid;
    border-top: 1px #747474 solid;
    width: 80%;
    text-align: center;
    padding: 10px;
}

.box-legal h3 {
    color: #747474;
    text-decoration: underline;
}

.box-legal p {
    color: #747474;
    font-weight: 300;
    text-align: justify;
}

.legal {
    width: 80%;
}

#legal button {
    position: initial;
    font-size: 16px;
    width: fit-content;
    padding: 10px;
    margin-top: 20px;
}

@media screen and (max-width: 700px) {

    #legal button {
      font-size: 16px;
      width: fit-content;
    }

    .box-legal {
        width: 80vw;
    }

    .box-legal .p-legal {
        text-align: left;
    }
}