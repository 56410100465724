#home {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

h1 {
    text-align: center;
    font-size: 2.5rem;
    text-transform: uppercase;
    background: linear-gradient(90deg, #AD918B 0%, #2E8976 81.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.home-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    z-index: 1;
    max-width: 1200px;
    gap: 40px;
}

.home-content-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
}

.home-content-right {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
}

.home-content-left p {
    text-align: center;
    width: 80%;
    color: #747474;
    margin-top: 20px;
}

.computer {
    width: 35%;
}

.home-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    gap: 15px;
}

/* Ajoutez ceci à votre fichier CSS */
@media (max-width: 780px) {
    h1 {
        font-size: 2rem; /* Réduisez la taille de la police */
    }

    .home-content {
        flex-direction: column; /* Changez la direction des éléments pour les empiler verticalement */
        gap: 20px; /* Augmentez l'espace entre les éléments */
        text-align: center; /* Centrez le contenu */
        margin: 100px 10px;
    }

    .home-content-left {
        width: 100%; /* Occupe toute la largeur disponible */
    }

    .computer {
        width: 70%; /* Réduisez la largeur de l'image */
        margin-top: 20px; /* Ajoutez un espace en haut de l'image */
    }

    .home-button {
        margin-top: 30px; /* Augmentez l'espace en haut des boutons */
    }
}
