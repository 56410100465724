#about {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#about h2 {
    z-index: 1;
}

.about-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 1;
    max-width: 1200px;
    gap: 40px;
}

.about-content-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
}

.about-content-left p {
    text-align: justify;
    color: #747474;
    margin-top: 20px;
}

.about-content-right {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
}

.logo-about {
    width: 70%;
}

/* Ajoutez ceci à votre fichier CSS */

@media (max-width: 780px) {
    .about-content {
        flex-direction: column; /* Changez la direction des éléments pour les empiler verticalement */
        gap: 20px; /* Augmentez l'espace entre les éléments */
        text-align: center; /* Centrez le contenu */
        margin: 100px 10px;
    }

    .about-content-left {
        width: 100%; /* Occupe toute la largeur disponible */
    }

    .logo-about {
        width: 60%; /* Réduisez la largeur du logo */
    }

    .about-content-left p {
        text-align: center; /* Centrez le texte */
        margin-top: 20px; /* Augmentez l'espace en haut du texte */
    }

    .about-content-right {
        margin-top: 20px; /* Ajoutez un espace en haut de l'image */
    }

    .mobile {
        width: 80%; /* Réduisez la largeur de l'image */
    }
}
