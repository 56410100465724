#contact {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.box-contact {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 50vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

.box-contact h1 {
    text-align: center;
    margin: 0;
}

.logo-contact {
    width: 60%;
    margin-top: 0;
    margin-bottom: 0;
}

.box-contact h2 {
    color: #747474;
    border-bottom: 1px #747474 solid;
    border-top: 1px #747474 solid;
    width: 80%;
    text-align: center;
    padding: 10px;
}

.localisation, .contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 10%;
    width: 80%;
}

.contact {
    flex-direction: column;
}

.localisation p,
.contact p {
    text-align: center;
    color: #747474;
    font-size: 16px;
    font-weight: 400;
}

@media screen and (max-width: 700px) {
    .box-contact {
        width: 80vw;
    }
}